import { FC, lazy, LazyExoticComponent } from 'react';
import { gnpRoute } from '../../pages/common/GnpRoute';
import { Route } from '../LayoutRoute';
import { NavigatePath } from '../constants/NavigatePath';
import { reportRoute } from '../../pages/report/ReportRoute';

// eslint-disable-next-line @typescript-eslint/ban-types
const CommonLayout: LazyExoticComponent<FC<{}>> = lazy(() => import('./CommonLayout'));

export const baseRoute: Route[] = [
  {
    path: NavigatePath.MAIN,
    element: <CommonLayout />,
    roles: [],
    isAuth: false,
    children: [...gnpRoute, ...reportRoute],
  },
];
