import { atom } from 'recoil';
import { StoargeEnum } from '../effect/constant/StorageEnum';
import { localStorageEffect } from '../effect/Storage';
import { YESNO } from '../../constants/BasicEnum';

/**
 * 사원정보
 */
interface MyEmployeeInfo {
  businessClassification: string;
  defaultGroupCompanyCode: string;
  dutyCode: string;
  dutyName: string;
  email: string;
  groupCompanyCode: string;
  groupCompanyName: string;
  groupCompanys: {
    code: string;
    name: string;
    description: string;
    logo: {
      fileSize: number;
    };
  }[];
  id: string;
  isAdmin: string;
  name: string;
  nickName: string;
  organizationCode: string;
  organizationName: string;
  positionCode: string;
  positionName: string;
  profilePicture: { fileName: string; filePath: string; fileSize: string };
  roles: [];
  uid: number;
  password: string;
  isNotification: YESNO;
}

/**
 * 사원정보 atom
 */
const myEmployeeInfoAtom = atom<MyEmployeeInfo>({
  key: 'myEmployeeInfo',
  default: {
    businessClassification: '',
    defaultGroupCompanyCode: '',
    dutyCode: '',
    dutyName: '',
    email: '',
    groupCompanyCode: '',
    groupCompanyName: '',
    groupCompanys: [],
    id: '',
    isAdmin: '',
    name: '',
    nickName: '',
    organizationCode: '',
    organizationName: '',
    positionCode: '',
    positionName: '',
    profilePicture: { fileName: '', filePath: '', fileSize: '' },
    roles: [],
    uid: 0,
    password: '',
    isNotification: YESNO.NO,
  } as MyEmployeeInfo,
  effects: [localStorageEffect(StoargeEnum.EMP)],
});

export { myEmployeeInfoAtom as empAtom, type MyEmployeeInfo as Emp };
