import { atom } from 'recoil';
import { GnpExecutionPlanProps, GoalProps } from '../../pages/common/type/types';
import { YESNO } from '../../utils/constants/BasicEnum';

// G&P+ or G&P+ 지시 선택
export const modalTypeAtom = atom<null | YESNO>({
  key: 'modalType',
  default: null,
});
export const modalStepAtom = atom<number>({
  key: 'modalStep',
  default: 0,
});

export const projectYnAtom = atom<boolean>({
  key: 'projectYn',
  default: false,
});

export const projectPlanAtom = atom<GnpExecutionPlanProps>({
  key: 'projectPlan',
  default: null,
});

export const projectGoalAtom = atom<GoalProps>({
  key: 'projectGoal',
  default: null,
});
