import { atom } from 'recoil';
import { StoargeEnum } from '../effect/constant/StorageEnum';
import { localStorageEffect } from '../effect/Storage';

/**
 * 인증
 */
interface Auth {
  /**
   * 사요자 식별자
   */
  userId: string;
  /**
   * 접근토큰
   */
  accessToken: string;
  /**
   * 그룹사 코드
   */
  groupCompanyCode: string;
}

/**
 * 인증 atom
 */
const authAtom = atom<Auth>({
  key: 'auth',
  default: {
    userId: '',
    accessToken: '',
  } as Auth,
  effects: [localStorageEffect(StoargeEnum.AUTH)],
});

export { authAtom, type Auth };
