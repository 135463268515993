import { atom } from 'recoil';
import { StoargeEnum } from '../effect/constant/StorageEnum';
import { localStorageEffect } from '../effect/Storage';

/**
 * 프로필 정보
 */
interface Profile {
  image_file: string;
  preview_URL: string;
}

/**
 * 프로필 atom
 */
const profileAtom = atom<Profile>({
  key: 'profile',
  default: {
    image_file: '',
    preview_URL: '',
  } as Profile,
  effects: [localStorageEffect(StoargeEnum.PROFILE)],
});

export { profileAtom, type Profile };
