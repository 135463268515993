import { CssBaseline, ThemeProvider } from '@mui/material';
import { RecoilRoot } from 'recoil';
import ErrorSuspense from './components/molecule/error-suspense/ErrorSuspense';
import { LayoutRoute } from './layouts/LayoutRoute';
import Theme from './theme';
import AuthRoutes from './utils/hooks/useAuthRoutes';
import { QueryClientProvider } from './utils/provider/query-client/QueryClientProvider';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <RecoilRoot>
        <QueryClientProvider>
          <ErrorSuspense>
            <AuthRoutes>
              <CssBaseline />
              <LayoutRoute />
            </AuthRoutes>
          </ErrorSuspense>
        </QueryClientProvider>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
