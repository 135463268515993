/**
 * YESNO
 */
export enum YESNO {
  /**
   * Y
   */
  YES = 'Y',
  /**
   * N
   */
  NO = 'N',
}
