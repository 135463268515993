import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { authAtom } from '../recoil/auth/Auth.atom';
import { searchTerm } from '../../store/Search.atom';
import { modalStepAtom, modalTypeAtom, projectYnAtom } from '../../store/gnpp-create/ProjectCreate.atom';
import { profileAtom } from '../recoil/emp/Profile.atom';
import { empAtom } from '../recoil/emp/Emp.atom';
import { employeesAtom, orgsAtom } from '../recoil/emp/OrgsEmployees.atom';
import { NavigatePath } from '../../layouts/constants/NavigatePath';
import { commonUtil } from '../util';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes = (props: AuthRoutesProps) => {
  const { children } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useRecoilValue(authAtom);
  /// 초기화 대상들
  // 검색어 Atom 초기화
  const resetSearchState = useResetRecoilState(searchTerm);
  // 프로젝트계획여부 Atom 초기화
  const resetProjectYnState = useResetRecoilState(projectYnAtom);
  // 프로필 Atom 초기화
  const resetProfileState = useResetRecoilState(profileAtom);
  // 사용자정보 Atom 초기화
  const resetUserState = useResetRecoilState(empAtom);
  //　인증정보 Atom 초기화
  const resetAuthState = useResetRecoilState(authAtom);
  // modalTypeAtom
  const resetModalTypeState = useResetRecoilState(modalTypeAtom);
  //modalStepAtom
  const resetModalStepState = useResetRecoilState(modalStepAtom);

  // 조직정보들
  const resetOrgsState = useResetRecoilState(orgsAtom);
  // 사원정보들
  const resetEmployeesState = useResetRecoilState(employeesAtom);

  useEffect(() => {
    loginCheck();
  }, [pathname]);

  const loginCheck = () => {
    //TODO: 로그인 데이터 로직 구현
    if (auth.userId === '') {
      resetSearchState();
      resetUserState();
      resetProfileState();
      resetAuthState();
      resetProjectYnState();
      resetModalTypeState();
      resetModalStepState();
      resetOrgsState();
      resetEmployeesState();
      localStorage.clear();
      navigate(NavigatePath.LOGIN);
      return;
    }
    // 아직 로그인 정보가 있을 때는 로그인화면으로 접근하면 메인으로 보내기...
    if (!commonUtil.isNull(auth.accessToken) && pathname === NavigatePath.LOGIN) {
      navigate(NavigatePath.MAIN);
      return;
    }
  };

  return <>{children}</>;
};

export default AuthRoutes;
