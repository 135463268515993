import { LazyExoticComponent, FC, lazy } from 'react';
import { NavigatePath } from '../../layouts/constants/NavigatePath';
import { Route } from '../../layouts/LayoutRoute';

// eslint-disable-next-line @typescript-eslint/ban-types
const Report: LazyExoticComponent<FC<{}>> = lazy(() => import('./entrypoint/Report'));
// eslint-disable-next-line @typescript-eslint/ban-types
const Content: LazyExoticComponent<FC<{}>> = lazy(() => import('./content/ReportContent'));
// eslint-disable-next-line @typescript-eslint/ban-types
const Result: LazyExoticComponent<FC<{}>> = lazy(() => import('./result/ReportResult'));

export const reportRoute: Route[] = [
  {
    // path: NavigatePath.REPORT,
    element: <Report />,
    roles: [],
    isAuth: false,
    children: [
      {
        path: NavigatePath.REPORT_CONTENT,
        element: <Content />,
        roles: [],
        isAuth: false,
      },
      {
        path: NavigatePath.REPORT_RESULT,
        element: <Result />,
        roles: [],
        isAuth: false,
      },
    ],
  },
];
