/**
 * 스토리지 상수
 */
export enum StoargeEnum {
  /**
   * 인증
   */
  AUTH = 'auth',
  /**
   * 기타
   */
  ETC = 'auth',
  /**
   * 사원정보
   */
  EMP = 'emp',
  /**
   * 프로필
   */
  PROFILE = 'profile',
  /**
   * 평가
   */
  GNPG_EVALUATION = 'gnpgEvaluation',
  /**
   * 소속조직정보
   */
  ORGS = 'orgs',
  /**
   * 소속조직사원정보
   */
  EMPLOYEES = 'employees',
  /**
   * 평가계획 사원정보
   */
  PLAN_EMPLOYEES = 'planEmployees',
}
