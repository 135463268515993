import { FC, lazy, LazyExoticComponent } from 'react';
import { NavigatePath } from '../../layouts/constants/NavigatePath';
import { Route } from '../../layouts/LayoutRoute';

// eslint-disable-next-line @typescript-eslint/ban-types
const GnpList: LazyExoticComponent<FC<{}>> = lazy(() => import('./GnpList'));
// eslint-disable-next-line @typescript-eslint/ban-types
const GnpSettingModal: LazyExoticComponent<FC<{}>> = lazy(() => import('../common/header/GnpSettingModal'));
// eslint-disable-next-line @typescript-eslint/ban-types
const GnpProfileModal: LazyExoticComponent<FC<{}>> = lazy(() => import('../common/header/GnpProfileModal'));
// eslint-disable-next-line @typescript-eslint/ban-types
const GnppCreateModal: LazyExoticComponent<FC<{}>> = lazy(() => import('../gnpp/create/template/GnppCreateModal'));

export const gnpRoute: Route[] = [
  {
    path: '',
    element: <GnpList />,
    roles: [],
    isAuth: false,
  },
  {
    path: NavigatePath.COMPANY_SETTING,
    element: <GnpSettingModal />,
    roles: [],
    isAuth: false,
  },
  {
    path: NavigatePath.PROFILE,
    element: <GnpProfileModal />,
    roles: [],
    isAuth: false,
  },
  {
    path: NavigatePath.NEW_GNPP,
    element: <GnppCreateModal />,
    roles: [],
    isAuth: false,
  },
  { path: NavigatePath.NEW_GNPP + '/:id', element: <GnpList />, roles: [], isAuth: false },
];
