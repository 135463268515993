import { PaletteOptions } from '@mui/material';

export const basicPalette: PaletteOptions = {
  black: { main: '#000000' },
  white: { main: '#FFFFFF' },
  primary: { main: '#3949AB' },
  secondary: { main: '#071F60' }, // indigo600
  secondaryLight: { main: '#5C6BC0' },
  indigo50: { main: '#E8EAF6' },
  indigo100: { main: '#C5CAE9' },
  indigo300: { main: '#7986CB' },
  indigo600: { main: '#3949AB' },
  pink50: { main: '#FCE4EC' },
  pink500: { main: '#E91E63' },
  pink600: { main: '#D81B60' },
  red100: { main: '#FFCDD2' },
  red900: { main: '#B71C1C' },
  green50: { main: '#E8F5E9' },
  green100: { main: '#C8E6C9' },
  green600: { main: '#43A047' },
  green900: { main: '#1B5E20' },
  grey50: { main: '#FAFAFA' },
  grey100: { main: '#F5F5F5' },
  grey200: { main: '#EEEEEE' },
  grey300: { main: '#E0E0E0' },
  grey400: { main: '#BDBDBD' }, // outlineVariant
  grey600: { main: '#757575' },
  grey800: { main: '#424242' },
  grey900: { main: '#212121' },
  amber50: { main: '#FFF8E1' },
  amber200: { main: '#FFE082' },
  primaryOpacity10: { main: 'rgba(57, 73, 171, 0.08)' },
  primaryOpacity20: { main: 'rgba(57, 73, 171, 0.12)' },
  primaryOpacity30: { main: 'rgba(57, 73, 171, 0.16)' },
  greyOpacity10: { main: 'rgba(21, 21, 21, 0.08)' },
  greyOpacity20: { main: 'rgba(21, 21, 21, 0.12)' },
  greyOpacity30: { main: 'rgba(21, 21, 21, 0.16)' },
};

export const defaultFont = {
  fontStyle: 'normal',
  lineHeight: '148%',
  letterSpacing: '-0.018em',
  color: 'currentColor',
  fontFamily: 'Pretendard',
};
