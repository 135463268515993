export const commonUtil = {
  isNull: function (value: any) {
    return value === undefined || value === null;
  },
};
export function formatDateTimeToFormat(dateString) {
  if (!dateString) {
    return null;
  }
  const dateObj = new Date(dateString);
  if (isNaN(dateObj.getTime())) {
    return null;
  }
  const isoDate = dateObj.toISOString().split('T');
  return isoDate[0] + ' ' + isoDate[1].substring(0, 8);
}

export function formatDateTimeString(dateString) {
  if (!dateString) {
    return null;
  }
  const dateObj = new Date(dateString);
  if (isNaN(dateObj.getTime())) {
    return null;
  }
  const isoDate = dateObj.toISOString().split('T');
  return isoDate[0] + ' ' + isoDate[1].substring(0, 5);
}

export function formatDateTimeToDateFormat(date) {
  if (!date) {
    return null;
  }
  const isoDate = date.toString().split('T');
  return isoDate[0];
}
