import { createTheme } from '@mui/material/styles';
import { basicPalette, defaultFont } from './ThemeConfig';
import type {} from '@mui/x-data-grid/themeAugmentation';

const Theme = createTheme({
  palette: {
    ...basicPalette,
    error: {
      light: '#e57373',
      main: '#D3322F',
      dark: '#000',
    },
    success: {
      light: '#81C784',
      main: '#2E7D32',
      dark: '#000',
    },
    warning: {
      light: '#000',
      main: '#ED6C02',
      dark: '#000',
    },
    info: {
      main: '#0288D1',
    },
  },
  spacing: 4,
  typography: {
    ...defaultFont,
    htmlFontSize: 16,
    displayLarge: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '3.5rem',
    },
    displayMedium: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '2.75rem',
    },
    displaySmall: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '2.25rem',
    },
    headlineLarge: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '2rem',
    },
    headlineMedium: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '1.75rem',
    },
    headlineSmall: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '1.5rem',
    },
    titleLarge: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '1.375rem',
    },
    titleMedium: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '1rem',
      letterSpacing: 0.15,
    },
    titleSmall: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '0.875rem',
      letterSpacing: 0.1,
    },
    bodyLarge: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '1rem',
      letterSpacing: 0.5,
    },
    bodyMedium: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '0.875rem',
      letterSpacing: 0.25,
    },
    bodySmall: {
      ...defaultFont,
      fontWeight: 400,
      fontSize: '0.75rem',
      letterSpacing: 0.4,
    },
    labelLargeP: {
      ...defaultFont,
      fontWeight: 600,
      fontSize: '0.875rem',
      letterSpacing: 0.1,
    },
    labelLarge: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '0.875rem',
      letterSpacing: 0.1,
    },
    labelMediumP: {
      ...defaultFont,
      fontWeight: 600,
      fontSize: '0.75rem',
      letterSpacing: 0.5,
    },
    labelMedium: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '0.75rem',
      letterSpacing: 0.5,
    },
    labelSmall: {
      ...defaultFont,
      fontWeight: 500,
      fontSize: '0.6875rem',
      letterSpacing: 0.5,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1024,
      xl: 1536,
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          fontSize: '18px',
          '& .MuiTouchRipple-root': {
            borderRadius: theme.spacing(2),
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& fieldset': {
            borderRadius: theme.spacing(2),
          },
        }),
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: theme.spacing(2),
          padding: theme.spacing(2, 0),
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: () => ({
          border: 0,
        }),
        columnHeader: ({ theme }) => ({
          background: theme.palette.grey50.main,
          borderTop: `1px solid ${theme.palette.grey400.main} !important`,
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        }),
        virtualScroller: () => ({
          borderRadius: '0 !important',
        }),
        row: ({ theme }) => ({
          background: theme.palette.white.main,
        }),
        cell: () => ({
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        }),
        withBorderColor: ({ theme }) => ({
          border: 0,
          borderTop: `1px solid ${theme.palette.grey400.main}`,
        }),
        footerContainer: ({ theme }) => ({
          border: `1px solid ${theme.palette.error.main}`,
        }),
        menu: ({ theme }) => ({
          borderRadius: theme.spacing(2),
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.24)',
          overflow: 'hidden',
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: '100%',
          marginTop: theme.spacing(6),
        }),
        toolbar: () => ({
          justifyContent: 'center',
          padding: '0 !important',

          '& .MuiPagination-text': {
            margin: 0,
          },
        }),
        spacer: () => ({
          display: 'none',
        }),
        displayedRows: () => ({
          display: 'none',
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: theme.spacing(3),
          maxWidth: 'none',
          boxShadow: 'none',
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          ...theme.typography.bodyMedium,
          color: theme.palette.white.main,
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        dense: ({ theme }) => ({
          '& span': {
            marginLeft: theme.spacing(-2),
            ...theme.typography.bodyLarge,
          },
        }),
      },
    },
  },
});

export default Theme;
