import { FC, lazy, LazyExoticComponent } from 'react';
import { NavigatePath } from '../../layouts/constants/NavigatePath';
import { Route } from '../../layouts/LayoutRoute';

/**
 * 맴버 관리 컴포넌트
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const Login: LazyExoticComponent<FC<{}>> = lazy(() => import('./Login'));

// eslint-disable-next-line @typescript-eslint/ban-types
const FindPassword: LazyExoticComponent<FC<{}>> = lazy(() => import('./FindPassword'));

/**
 * 계정정보 관리
 */
export const authRoute: Route[] = [
  {
    path: NavigatePath.LOGIN,
    element: <Login />,
    roles: [],
    isAuth: false,
  },
  {
    path: NavigatePath.FIND_PASSWORD,
    element: <FindPassword />,
    roles: [],
    isAuth: false,
  },
];
