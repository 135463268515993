export enum NavigatePath {
  /**
   * 메인
   */
  MAIN = '/gnp',
  /**
   * 로그인
   */
  LOGIN = '/login',
  /**
   * 비밀번호 찾기
   */
  FIND_PASSWORD = '/findPassword',
  /**
   * 기업설정
   */
  COMPANY_SETTING = 'company-setting',
  /**
   * 프로필
   */
  PROFILE = 'gnp-profile',
  /**
   * 새 Gnp+
   */
  NEW_GNPP = 'gnpp-create',
  /**
   * 리포트
   */
  REPORT_CONTENT = 'report-content',
  /**
   * 리포트 결과
   */
  REPORT_RESULT = 'report-result',
}
